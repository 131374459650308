import React from "react";

const Consultinencey = (props) => {
  return (
    <section
      className={`wpo-contact-section-s2 section-padding ${props.cnClass}`}
      id="contact"
    >
      <div className="container">
        <div className="wpo-contact-section-wrapper">
          <div className="row justify-content-center">
            <div className="col col-xl-11 col-lg-10">
              <div className="contact-info-wrap">
                <div className="contact-info-title">
                  <h3>Contact Info</h3>
                </div>
                <div className="contact-info-item">
                  <div className="contact-info-icon">
                    <i className="fi flaticon-001-house"></i>
                  </div>
                  <div className="contact-info-text">
                    <h4>Office Address</h4>
                    <p>
                      1st floor, Maheshwari Building, MG Road, Thrissur, Kerala
                      - 680001
                    </p>
                  </div>
                </div>
                <div className="contact-info-item">
                  <div className="contact-info-icon">
                    <i className="fi flaticon-002-open"></i>
                  </div>
                  <div className="contact-info-text">
                    <h4>Email Address</h4>
                    <p>advs.akassociates@gmail.com</p>
                  </div>
                </div>
                <div className="contact-info-item">
                  <div className="contact-info-icon">
                    <i className="fi flaticon-003-headphone"></i>
                  </div>
                  <div className="contact-info-text">
                    <h4>Phone</h4>
                    <p>+91 989 5975 645</p>
                  </div>
                </div>
                {/* <div className="contact-info-item">
                  <div className="contact-info-icon">
                    <i className="fi flaticon-004-clock"></i>
                  </div>
                  <div className="contact-info-text">
                    <h4>Office Hour</h4>
                    <p>Mon-Sat: 9:15am – 5:45pm</p>
                  </div>
                </div> */}
              </div>
            </div>
            {/* <div className="col col-xl-5 col-lg-6 col-md-12 col-12">
              <div className="wpo-contact-form-area">
                <div className="form-title">
                  <span>Contact Us</span>
                  <h3>Free Consulting</h3>
                </div>
                <ConsultingForm />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Consultinencey;
