import React, { Fragment, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import { Link } from "react-router-dom";
import "./style.css";

const menus = [
  {
    id: 1,
    title: "Home",
    link: "#/",
    // submenu: [
    //     {
    //         id: 11,
    //         title: 'Home style 1',
    //         link: '/home'
    //     },
    //     {
    //         id: 12,
    //         title: 'Home style 2',
    //         link: '/home2'
    //     },
    //     {
    //         id: 13,
    //         title: 'Home style 3',
    //         link: '/home3'
    //     },
    //     {
    //         id: 14,
    //         title: 'Home style 4',
    //         link: '/home4'
    //     }
    // ]
  },
  {
    id: 2,
    title: "About us",
    link: "#about",
  },

  // {
  //   id: 3,
  //   title: "Pages",
  //   link: "/",
  //   submenu: [
  //     {
  //       id: 31,
  //       title: "About Us",
  //       link: "/about",
  //     },
  //     {
  //       id: 3222,
  //       title: "Attorneys",
  //       link: "/attorneys",
  //     },
  //     {
  //       id: 322,
  //       title: "Attorneys Single",
  //       link: "/attorneys-single/Jacob-Jones",
  //     },
  //     {
  //       id: 33,
  //       title: "Pricing",
  //       link: "/pricing",
  //     },
  //     {
  //       id: 34,
  //       title: "404 Error",
  //       link: "/404",
  //     },
  //     {
  //       id: 3454,
  //       title: "FAQ",
  //       link: "/faq",
  //     },
  // {
  //     id: 35,
  //     title: 'Login',
  //     link: '/login'
  // },
  // {
  //     id: 36,
  //     title: 'Register',
  //     link: '/register'
  // },
  // ],
  //  },

  {
    id: 6,
    title: "Practice",
    link: "#practice",
    // submenu: [
    //   {
    //     id: 61,
    //     title: "Practice",
    //     link: "/practice",
    //   },
    //   {
    //     id: 62,
    //     title: "Practice Style 2",
    //     link: "/practice-s2",
    //   },
    //   {
    //     id: 63,
    //     title: "Practice Style 3",
    //     link: "/practice-s3",
    //   },
    //   {
    //     id: 64,
    //     title: "Practice single",
    //     link: "/practice-single/Criminal-Law",
    //   },
    // ],
  },
  {
    id: 7,
    title: "Our Experts",
    link: "#our-experts",
    // submenu: [
    //   {
    //     id: 71,
    //     title: "Project",
    //     link: "/case",
    //   },
    //   {
    //     id: 72,
    //     title: "Cases Style 2",
    //     link: "/case-s2",
    //   },
    //   {
    //     id: 73,
    //     title: "Cases Style 3",
    //     link: "/case-s3",
    //   },
    //   {
    //     id: 74,
    //     title: "Cases single",
    //     link: "/case-single/Criminal-Law",
    //   },
    // ],
  },
  // {
  //     id: 4,
  //     title: 'Shop',
  //     link: '/shop',
  //     submenu: [
  //         {
  //             id: 41,
  //             title: 'Shop',
  //             link: '/shop',
  //         },
  //         {
  //             id: 42,
  //             title: 'Shop Single',
  //             link: '/product-single/Winning-Arguments'
  //         },
  //         {
  //             id: 43,
  //             title: 'Cart',
  //             link: '/cart'
  //         },
  //         {
  //             id: 44,
  //             title: 'Checkout',
  //             link: '/checkout'
  //         },
  //     ]
  // },
  // {
  //     id: 5,
  //     title: 'Blog',
  //     link: '/blog',
  //     submenu: [
  //         {
  //             id: 51,
  //             title: 'Blog',
  //             link: '/blog'
  //         },
  //         {
  //             id: 52,
  //             title: 'Blog Left sidebar',
  //             link: '/blog-left-sidebar'
  //         },
  //         {
  //             id: 53,
  //             title: 'Blog full width',
  //             link: '/blog-fullwidth'
  //         },
  //         {
  //             id: 54,
  //             title: 'Blog single',
  //             link: '/blog-single/Justice-May-For-You-If-You-Are-Innocent'
  //         },
  //         {
  //             id: 55,
  //             title: 'Blog single Left sidebar',
  //             link: '/blog-single-left-sidebar/Justice-May-For-You-If-You-Are-Innocent'
  //         },
  //         {
  //             id: 56,
  //             title: 'Blog single full width',
  //             link: '/blog-single-fullwidth/Justice-May-For-You-If-You-Are-Innocent'
  //         },
  //     ]
  // },
  {
    id: 88,
    title: "Contact",
    link: "#contact",
  },
];

const MobileMenu = () => {
  const [openId, setOpenId] = useState(0);
  const [menuActive, setMenuState] = useState(false);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
    setMenuState(false);
  };

  return (
    <div>
      <div className={`mobileMenu ${menuActive ? "show" : ""}`}>
        <div className="menu-close">
          <div className="clox" onClick={() => setMenuState(!menuActive)}>
            <i className="ti-close"></i>
          </div>
        </div>

        <ul className="responsivemenu">
          {menus.map((item, mn) => {
            return (
              <ListItem
                className={item.id === openId ? "active" : null}
                key={mn}
              >
                {item.submenu ? (
                  <Fragment>
                    <p
                      onClick={() =>
                        setOpenId(item.id === openId ? 0 : item.id)
                      }
                    >
                      {item.title}
                      <i
                        className={
                          item.id === openId
                            ? "fa fa-angle-up"
                            : "fa fa-angle-down"
                        }
                      ></i>
                    </p>
                    <Collapse
                      in={item.id === openId}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List className="subMenu">
                        <Fragment>
                          {item.submenu.map((submenu, i) => {
                            return (
                              <ListItem key={i}>
                                <a
                                  onClick={ClickHandler}
                                  className="active"
                                  href={submenu.link}
                                >
                                  {submenu.title}
                                </a>
                              </ListItem>
                            );
                          })}
                        </Fragment>
                      </List>
                    </Collapse>
                  </Fragment>
                ) : (
                  <a className="active" href={item.link} onClick={ClickHandler}>
                    {item.title}
                  </a>
                )}
              </ListItem>
            );
          })}
        </ul>
      </div>

      <div className="showmenu" onClick={() => setMenuState(!menuActive)}>
        <button type="button" className="navbar-toggler open-btn">
          <span className="icon-bar first-angle"></span>
          <span className="icon-bar middle-angle"></span>
          <span className="icon-bar last-angle"></span>
        </button>
      </div>
    </div>
  );
};

export default MobileMenu;
