import React from "react";
import "./desclaimer.css";

export default function Desclaimer({ onSetDesclaimer }) {
  const handleAgree = () => {
    onSetDesclaimer(false);
  };
  const handleDisagree = () => {
    console.log("button clicked");
    window.location.href = "https://www.google.com";
  };

  return (
    <div className="desclaimer">
      <div>
        <p>
          AK Associates is bound by the Bar Council of India (BCI) rules
          governing the advertising and solicitation of work by advocates. The
          information provided on this website is limited to the extent
          permitted by these rules. This website is not intended to solicit work
          or advertise the services of AK Associates.
        </p>
      </div>

      <div className="desclaimerBtnSection">
        <button className="theme-btn-s2" onClick={handleAgree}>
          Agree
        </button>
        <button className="theme-btn-s2" onClick={handleDisagree}>
          Disagree
        </button>
      </div>
    </div>
  );
}
