// import simg from "../images/practice/img-2.jpg";
// import simg2 from "../images/practice/img-1.jpg";
// import simg3 from "../images/practice/img-3.jpg";
// import simg4 from "../images/practice/img-4.jpg";
// import simg5 from "../images/practice/img-5.jpg";
// import simg6 from "../images/practice/img-6.jpg";

import sSingleimg1 from "../images/service-single/2.jpg";
import sSingleimg2 from "../images/service-single/3.jpg";

import insuranceImg from "../images/practice/insurance.webp";
import consumerImg from "../images/practice/consumer.webp";
import maritimeImg from "../images/practice/maritime.webp";
import sarafaesiImg from "../images/practice/sarafaesi.webp";
import realEstateImg from "../images/practice/real estate.webp";
import pmlaImg from "../images/practice/pmla.webp";
import cyberLawImg from "../images/practice/10/1.webp";
import IntellectualImg from "../images/practice/10/3.webp";
import electionImg from "../images/practice/10/5.webp";
import immigrationImg from "../images/practice/10/8.webp";
import familyImg from "../images/practice/10/9.webp";
import InternationalImg from "../images/practice/10/10.webp";
import BankruptcyImg from "../images/practice/10/11.webp";
import ArbitrationsImg from "../images/practice/10/6.webp";
import CivilImg from "../images/practice/10/7.webp";
import financialImg from "../images/practice/10/2.webp";
import CriminalLawImg from "../images/practice/10/12.webp";
import CompanyImg from "../images/practice/10/13.webp";
// import img4 from "../images/practice/10/4.webp";

const Practices = [
  {
    Id: "1",
    sImg: insuranceImg,
    sTitle: "Insurance Law",
    slug: "#practice",
    description:
      "Expert guidance on insurance claims, ensuring maximum benefits.",
    des2: "The lower-numbered purposes are better understood and practiced",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    icon: "flaticon-012-family",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "2",
    sImg: consumerImg,
    sTitle: "Consumer law",
    slug: "#practice",
    description:
      "Protecting consumer rights through litigation and dispute resolution.",
    des2: "The lower-numbered purposes are better understood and practiced",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    icon: "flaticon-015-patient",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "3",
    sImg: maritimeImg,
    sTitle: "Maritime Law",
    slug: "#practice",
    description:
      "Addressing complex maritime legal issues with specialized expertise.",
    des2: "The lower-numbered purposes are better understood and practiced",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    icon: "flaticon-016-gun",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "4",
    sImg: sarafaesiImg,
    sTitle: "SARFAESI",
    slug: "#practice",
    description:
      "Strategic legal counsel for individuals and businesses facing recovery proceedings.",
    des2: "The lower-numbered purposes are better understood and practiced",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    icon: "flaticon-014-learning",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "5",
    sImg: realEstateImg,
    sTitle: "Real Estate law",
    slug: "#practice",
    description:
      "Comprehensive legal solutions for property transactions and disputes.",
    des2: "The lower-numbered purposes are better understood and practiced",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    icon: "flaticon-013-planning",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "6",
    sImg: pmlaImg,
    sTitle: "PMLA",
    slug: "#practice",
    description:
      "Compliance assistance and representation in PMLA-related matters.",
    des2: "The lower-numbered purposes are better understood and practiced",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    icon: "flaticon-017-agreement",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "7",
    sImg: ArbitrationsImg,
    sTitle: "Arbitrations",
    slug: "#practice",
    description:
      "Resolving disputes efficiently through alternative dispute resolution.",
    des2: "The lower-numbered purposes are better understood and practiced",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    icon: "flaticon-017-agreement",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "8",
    sImg: BankruptcyImg,
    sTitle: "Bankruptcy and Insolvency",
    slug: "#practice",
    description:
      "Guiding individuals and businesses through financial distress.",
    des2: "The lower-numbered purposes are better understood and practiced",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    icon: "flaticon-017-agreement",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "9",
    sImg: CriminalLawImg,
    sTitle: "Criminal Law",
    slug: "#practice",
    description: "Aggressive defense and prosecution in criminal matters.",
    des2: "The lower-numbered purposes are better understood and practiced",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    icon: "flaticon-017-agreement",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "10",
    sImg: cyberLawImg,
    sTitle: "Cyber Law and E-Commerce",
    slug: "#practice",
    description:
      "Protecting digital assets and addressing online legal challenges.",
    des2: "The lower-numbered purposes are better understood and practiced",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    icon: "flaticon-017-agreement",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "11",
    sImg: CivilImg,
    sTitle: "Civil Law",
    slug: "#practice",
    description: "Resolving disputes between individuals or entities.",
    des2: "The lower-numbered purposes are better understood and practiced",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    icon: "flaticon-017-agreement",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "11",
    sImg: CompanyImg,
    sTitle: "Company Law",
    slug: "#practice",
    description: "Advancing corporate interests through legal expertise.",
    des2: "The lower-numbered purposes are better understood and practiced",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    icon: "flaticon-017-agreement",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "13",
    sImg: electionImg,
    sTitle: "Election Law",
    slug: "#practice",
    description: "Ensuring fair and transparent electoral processes.",
    des2: "The lower-numbered purposes are better understood and practiced",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    icon: "flaticon-017-agreement",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "14",
    sImg: familyImg,
    sTitle: "Family Law",
    slug: "#practice",
    description: "Providing legal support for families in transition.",
    des2: "The lower-numbered purposes are better understood and practiced",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    icon: "flaticon-017-agreement",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "15",
    sImg: financialImg,
    sTitle: "Financial Recovery",
    slug: "#practice",
    description: "Recovering debts and financial losses.",
    des2: "The lower-numbered purposes are better understood and practiced",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    icon: "flaticon-017-agreement",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "16",
    sImg: immigrationImg,
    sTitle: "Immigration Law",
    slug: "#practice",
    description: "Assisting with immigration processes and challenges.",
    des2: "The lower-numbered purposes are better understood and practiced",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    icon: "flaticon-017-agreement",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "17",
    sImg: IntellectualImg,
    sTitle: "Intellectual Property Rights",
    slug: "#practice",
    description: "Protecting and enforcing creative assets.",
    des2: "The lower-numbered purposes are better understood and practiced",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    icon: "flaticon-017-agreement",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "18",
    sImg: InternationalImg,
    sTitle: "International Law",
    slug: "#practice",
    description: "Managing global legal matters.",
    des2: "The lower-numbered purposes are better understood and practiced",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    icon: "flaticon-017-agreement",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
];
export default Practices;
