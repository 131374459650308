import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/logo2.svg";
import Practices from "../../api/Practices";

const Footer = (props) => {
  const practicesSliceFirst = Practices.slice(0, 9);
  const practicesSliceSecond = Practices.slice(9);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  return (
    <footer className="wpo-site-footer">
      <div className="wpo-upper-footer">
        <div className="container">
          <div className="row">
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget about-widget">
                <div className="logo widget-title">
                  <img src={Logo} alt="blog" />
                </div>
                <p>
                  AK Associates is a premier law firm dedicated to providing
                  effective legal solutions and building strong client
                  relationships on trust, communication, and results.
                </p>
                <ul>
                  <li>
                    <Link onClick={ClickHandler} to="/#">
                      <i className="ti-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/#">
                      <i className="ti-twitter-alt"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={ClickHandler}
                      to="https://www.linkedin.com/company/akshaykumarassociates/about/"
                      target="_blank"
                    >
                      <i className="ti-linkedin"></i>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/#">
                      <i className="ti-instagram"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col col-lg-2 offset-lg-1 col-md-6 col-sm-12 col-12">
              <div className="widget link-widget">
                <div className="widget-title">
                  <h3>Quick Links</h3>
                </div>
                <ul>
                  <li>
                    <a onClick={ClickHandler} href="#home">
                      Home
                    </a>
                  </li>
                  <li>
                    <a onClick={ClickHandler} href="#about">
                      About Us
                    </a>
                  </li>
                  <li>
                    <a onClick={ClickHandler} href="#practice">
                      Practice
                    </a>
                  </li>
                  <li>
                    <a onClick={ClickHandler} href="#attorneys">
                      Our Experts
                    </a>
                  </li>
                  <li>
                    <a onClick={ClickHandler} href="#case">
                      Contact
                    </a>
                  </li>
                  {/* <li>
                    <Link onClick={ClickHandler} to="/testimonial">
                      Testimonial
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="col col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="widget link-widget s2">
                <div className="widget-title">
                  <h3>Practice Area</h3>
                </div>
                <div className="practice-areas">
                  <ul>
                    {practicesSliceFirst.map((practice, Sitem) => (
                      <li key={Sitem}>
                        {/* <a
                        onClick={ClickHandler}
                        href={`${practice.slug}`}
                      > */}
                        {practice.sTitle}
                        {/* </a> */}
                      </li>
                    ))}
                  </ul>
                  <ul>
                    {practicesSliceSecond.map((practice, Sitem) => (
                      <li key={Sitem}>
                        {/* <a
                        onClick={ClickHandler}
                        href={`${practice.slug}`}
                      > */}
                        {practice.sTitle}
                        {/* </a> */}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              {/* <div className="widget newsletter-widget">
                <div className="widget-title">
                  <h3>Subscribe Newsletter</h3>
                </div>
                <form onSubmit={SubmitHandler}>
                  <div className="input-1">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Your Email Address..."
                      required=""
                    />
                  </div>
                  <div className="submit clearfix">
                    <button type="submit">Subscribe</button>
                  </div>
                </form>
              </div> */}
              {/* <ul className="demo-social">
                  <li>
                    <Link onClick={ClickHandler} to="/#">
                      <i className="ti-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/#">
                      <i className="ti-twitter-alt"></i>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/#">
                      <i className="ti-linkedin"></i>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/#">
                      <i className="ti-instagram"></i>
                    </Link>
                  </li>
                </ul> */}
            </div>
          </div>
        </div>
      </div>
      <div className="wpo-lower-footer">
        <div className="container">
          <div className="row align-items-center">
            <div className="col col-lg-6 col-md-12 col-12">
              <ul>
                <li>
                  Copyright &copy; 2024{" "}
                  <Link onClick={ClickHandler} to="/">
                    AK Associates
                  </Link>
                  . All rights reserved.
                </li>
              </ul>
            </div>
            <div className="col col-lg-6 col-md-12 col-12">
              <div className="link">
                <ul>
                  <li>
                    <Link onClick={ClickHandler} to="/">
                      Privace & Policy
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/">
                      Terms
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/">
                      About us
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/">
                      FAQ
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
