import React from "react";
import abimg2 from "../../images/about/akshay-sree.webp";

const About = (props) => {
  return (
    <section
      className={`wpo-about-section-s3 section-padding ${props.abClass}`}
      id="about"
    >
      <div className="container">
        <div className="wpo-section-title-s2">
          <span>About Us</span>
          <h2>Why Clients Choose Us?</h2>
        </div>
        <div className="wpo-about-section-wrapper">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12 col-12">
              <div className="wpo-about-content">
                <h2>Welcome to AK Associates : Your Top Legal Firm Choice</h2>
                <div className="wpo-about-content-inner">
                  <p>
                    AK Associates is one of the top legal firms devoted to
                    providing remarkable legal services. Our accomplished group
                    of attorneys is committed to defending your rights and
                    achieving successful results for you.
                  </p>
                  <p>
                    Our law firm is built on our strong commitment to our
                    clients, offering complete legal answers that fit your
                    specific needs. With a keen focus on integrity and
                    excellence, we make sure that our clients can trust us with
                    their future.
                  </p>
                  <p>
                    Allow us to advocate for you, be your confidant and
                    represent you boldly.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-12">
              <div className="wpo-about-img">
                <img src={props.abimg} alt="" />
                <div className="about-img-inner">
                  <img src={abimg2} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="visible-text">
            <h2>About</h2>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
