// import atimg1 from "../images/team/img-1.jpg";
// import atimg2 from "../images/team/img-2.jpg";
// import atimg3 from "../images/team/img-3.jpg";
// import atimg4 from "../images/team/img-4.jpg";

import akshayImg from "../images/team/akshay.webp";
import shyamImg from "../images/team/shyam.webp";
import nishaImg from "../images/team/nisha.webp";
import reshmithaImg from "../images/team/reshmitha.webp";
import paulceImg from "../images/team/paulce.webp";

const Attorneys = [
  {
    Id: "1",
    AtImg: akshayImg,
    name: "Adv. Akshay Kumar",
    slug: "Robert-Fox",
    title: "Corporate & Maritime Lawyer",
  },
  {
    Id: "2",
    AtImg: shyamImg,
    name: "Adv. Shyam Nair",
    slug: "Jane-Cooper",
    title: "Attorney and Counsellor at Law",
  },
  {
    Id: "3",
    AtImg: nishaImg,
    name: "Adv. Nisha V C",
    slug: "Adv. Nisha V C",
    title: "IPR and Contract Attorney",
  },
  {
    Id: "4",
    AtImg: reshmithaImg,
    name: "Adv. Reshmitha K R",
    slug: "Adv. Reshmitha K R",
    title: "Corporate Law & Litigation",
  },
  // {
  //   Id: "5",
  //   AtImg: paulceImg,
  //   name: "Adv. Paulce Kattukaren",
  //   slug: "Jane-Cooper",
  //   title: "MTE & Data Protection Attorney",
  // },
];

export default Attorneys;
