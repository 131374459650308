import React, { Fragment, useState } from "react";
import Hero from "../../components/hero/hero";
import About from "../../components/about/about";
import Navbar from "../../components/Navbar/Navbar";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Practice from "../../components/Practice";
import Footer from "../../components/footer/Footer";
import Attorney from "../../components/attorneys";
import abimg from "../../images/about/about-second.webp";
import Desclaimer from "../../components/Desclaimer/Desclaimer";
import Consultinencey from "../../components/Consultinencey/Consultinencey";

// import CaseStudies from '../../components/CaseStudies/CaseStudies';
// import BlogSection from '../../components/BlogSection/BlogSection';
// import FunFact from "../../components/FunFact";
// import Testimonial from "../../components/Testimonial/Testimonial";

const HomePage = () => {
  const [desclaimer, setDesclaimer] = useState(true);
  return (
    <Fragment>
      {desclaimer ? (
        <Desclaimer onSetDesclaimer={setDesclaimer} />
      ) : (
        <>
          <Navbar hclass={"wpo-header-style-2"} topbarNone={"topbar-none"} />
          <Hero />
          <About abimg={abimg} />
          <Practice />
          {/* <Testimonial /> */}
          {/* <CaseStudies vClass={'d-none'}/> */}
          {/* <FunFact /> */}
          <Attorney vClass={"d-none"} />
          <Consultinencey />
          {/* <BlogSection vClass={'d-none'}/> */}
          <Footer />
          <Scrollbar />
        </>
      )}
    </Fragment>
  );
};
export default HomePage;
