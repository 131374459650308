import React from "react";
import { Link } from "react-router-dom";
import MobileMenu from "../MobileMenu/MobileMenu";
// import { totalPrice } from "../../utils";
// import { connect } from "react-redux";
// import { removeFromCart } from "../../store/actions/action";
import Logo from "../../images/logo2.svg";
import HeaderTopbar from "../HeaderTopbar";

const Header = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <header id="header" className={props.topbarClass}>
      <HeaderTopbar />
      <div className={`wpo-site-header ${props.hclass}`}>
        <nav className="navigation navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                <div className="mobail-menu">
                  <MobileMenu />
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-6">
                <div className="navbar-header">
                  <Link onClick={ClickHandler} className="navbar-brand" to="/">
                    <img src={Logo} alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-8 col-md-1 col-1">
                <div
                  id="navbar"
                  className="collapse navbar-collapse navigation-holder"
                >
                  <button className="menu-close">
                    <i className="ti-close"></i>
                  </button>

                  <ul className="nav navbar-nav mb-2 mb-lg-0">
                    <li className="menu-item-has-children">
                      <Link onClick={ClickHandler} to="/">
                        Home
                      </Link>
                      {/* <ul className="sub-menu">
                        <li>
                          <Link onClick={ClickHandler} to="/home">
                            Home style 1
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/home2">
                            Home style 2
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/home3">
                            Home style 3
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/home4">
                            Home style 4
                          </Link>
                        </li>
                      </ul> */}
                    </li>
                    <li className="menu-item-has-children">
                      <a onClick={ClickHandler} href="#about">
                        About Us
                      </a>
                      {/* <ul className="sub-menu">
                        <li>
                          <Link onClick={ClickHandler} to="/about">
                            About Us
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/attorneys">
                            Attorneys
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={ClickHandler}
                            to="/attorneys-single/Jacob-Jones"
                          >
                            Attorneys single
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/terms">
                            Terms & Coditions
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/pricing">
                            Pricing
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/404">
                            404 Error
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/faq">
                            FAQ
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/login">
                            Login
                          </Link>
                        </li> 
                         <li>
                          <Link onClick={ClickHandler} to="/register">
                            Register
                          </Link>
                        </li> 
                      </ul> */}
                    </li>
                    <li className="menu-item-has-children">
                      <a onClick={ClickHandler} href="#practice">
                        Practice
                      </a>
                      {/* <ul className="sub-menu">
                        <li>
                          <Link onClick={ClickHandler} to="/practice">
                            Practice
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/practice-s2">
                            Practice Style 2
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/practice-s3">
                            Practice Style 3
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={ClickHandler}
                            to="/practice-single/Criminal-Law"
                          >
                            Practice Single
                          </Link>
                        </li>
                      </ul> */}
                    </li>
                    <li className="menu-item-has-children">
                      <a onClick={ClickHandler} href="#our-experts">
                        Our Experts
                      </a>
                      {/* <ul className="sub-menu">
                        <li>
                          <Link onClick={ClickHandler} to="/case">
                            Cases
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/case-s2">
                            Cases Style 2
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/case-s3">
                            Cases Style 3
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={ClickHandler}
                            to="/case-single/Criminal-Law"
                          >
                            Cases Single
                          </Link>
                        </li>
                      </ul> */}
                    </li>
                    {/* <li className="menu-item-has-children">
                      <Link onClick={ClickHandler} to="/">
                        Shop
                      </Link>
                      <ul className="sub-menu">
                        <li>
                          <Link onClick={ClickHandler} to="/shop">
                            Shop
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={ClickHandler}
                            to="/product-single/Principles-and-Policies"
                          >
                            Shop Single
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/cart">
                            Cart
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/checkout">
                            Checkout
                          </Link>
                        </li>
                      </ul>
                    </li> */}
                    {/* <li className="menu-item-has-children">
                      <Link onClick={ClickHandler} to="/">
                        Blog
                      </Link>
                      <ul className="sub-menu">
                        <li>
                          <Link onClick={ClickHandler} to="/blog">
                            Blog right sidebar
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/blog-left-sidebar">
                            Blog left sidebar
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/blog-fullwidth">
                            Blog fullwidth
                          </Link>
                        </li>
                        <li className="menu-item-has-children">
                          <Link onClick={ClickHandler} to="/">
                            Blog details
                          </Link>
                          <ul className="sub-menu">
                            <li>
                              <Link
                                onClick={ClickHandler}
                                to="/blog-single/Justice-May-For-You-If-You-Are-Innocent"
                              >
                                Blog details right sidebar
                              </Link>
                            </li>
                            <li>
                              <Link
                                onClick={ClickHandler}
                                to="/blog-single-left-sidebar/Justice-May-For-You-If-You-Are-Innocent"
                              >
                                Blog details left sidebar
                              </Link>
                            </li>
                            <li>
                              <Link
                                onClick={ClickHandler}
                                to="/blog-single-fullwidth/Justice-May-For-You-If-You-Are-Innocent"
                              >
                                Blog details fullwidth
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li> */}
                    <li>
                      <a onClick={ClickHandler} href="#contact">
                        Contact
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-2"></div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
