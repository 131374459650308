import React from "react";
import { Link } from "react-router-dom";
import heroImg from "../../images/home/hero-pic.webp";

const Hero = () => {
  return (
    <section className="static-hero" id="hero-super">
      <div className="hero-container">
        <div className="hero-inner">
          <div className="container">
            <div className="hero-text-wrap">
              <div className="sub-title">
                <h1>
                  Akshay Kumar Associates : <br /> Your Trusted Partner for Best
                  Legal Services
                </h1>
              </div>
              <div className="slide-title">
                <h2>Best Legal Services For Client Success</h2>
              </div>
              <div className="slide-text">
                <p>
                  With a steadfast commitment to excellence, we have earned the
                  reputation of being among the top providers of legal services.
                  We dedicate our team of attorneys to delivering superior legal
                  counsel and achieving top results for our clients.
                </p>
              </div>
              <div className="clearfix"></div>
              <div data-swiper-parallax="500" className="slide-btns">
                <a href="#practice" className="theme-btn-s2">
                  Explore more
                </a>
              </div>
            </div>
            <div className="lawyer-pic">
              <img src={heroImg} alt="" />
              <div className="lawyer-shape"></div>
              {/* <div className="exprience">
                <div className="exprience-left">
                  <h4>25</h4>
                </div>
                <div className="exprience-right">
                  <h4>Years Of Experience</h4>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
