import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import Practices from "../../api/Practices";

const settings = {
  dots: false,
  arrows: true,
  speed: 1000,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Practice = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <section
      className={`wpo-practice-section-s2 ${props.prClass}`}
      id="practice"
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5">
            <div className="wpo-section-title">
              <span>How Can We Help You</span>
              <h2>AK Associates: Legal Services</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="wpo-practice-wrap">
          <div className="wpo-practice-items">
            <Slider {...settings}>
              {Practices.map((practice, Pitem) => (
                <div className="wpo-practice-item" key={Pitem}>
                  <div className="wpo-practice-img">
                    <img src={practice.sImg} alt="" />
                  </div>
                  <div className="wpo-practice-content">
                    <div className="wpo-practice-content-top">
                      <div className="b-top">
                        <div className="b-top-inner">
                          <h2>
                            <a
                              onClick={ClickHandler}
                              href={`${practice.slug}`}
                            >
                              {practice.sTitle}
                            </a>
                          </h2>
                          <p>{practice.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Practice;
